import React from 'react';
import imgSrc from '../assets/images/Flyer_for_BLTD_1.png';
import "./Projects.scss"

const Projects = (props) => {
    return (
        <div id="projects">
            {/*<div className="text-center mb-3">*/}
            {/*    <a href="https://forms.gle/pFDvWa6fEMKBzJ6K7">*/}
            {/*        <img id="imgBLTDFlyer" src={imgSrc} />*/}
            {/*    </a>*/}
            {/*</div>*/}
            {/*<div className="text-center">*/}
            {/*    <a href="https://forms.gle/pFDvWa6fEMKBzJ6K7" target="_blank" className="btn btn-primary">CLICK HERE TO SIGN UP</a>*/}
            {/*</div>*/}

            <div className="text-center p-4 mb-4" style={{ backgroundColor: 'rgb(244, 210, 97)', borderRadius: '6px' }} >
                <h2>Before the Light Dims</h2>
                <span>‘Before the Light Dims’ is a project that hopes to preserve the lived experiences of Southeast Asian immigrants and refugees who may not have had an opportunity to share their stories. As a visual learner, I found how impactful visual imagery and multimedia can be to portray resonating stories and experiences. Growing up as a daughter of refugees, I would often hear about the violence and trauma my family experienced before escaping Vietnam and never really knew how to navigate these spaces. To have a deeper understanding and hold space for communities that want to be heard, I sought opportunities that helped me learn from folks with lived experiences and continue to challenge my biases and beliefs. Before the Light Dims holds spaces for storytellers who want to share their story or leave something behind for future generations, which will be collected and curated into a creative and mixed-media digital archive. If you or anyone you know is interested in being a part of this digital library, please contact me at <a href="mailto:seasianmango@gmail.com">seasianmango@gmail.com</a></span>
            </div>
        </div>
    )
}

export default Projects;