import React, { Component, useRef } from 'react';
import imgSrc from '../assets/images/MaryNguyen.jpg';
import { Gallery } from '../components/Gallery';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import { HashLink } from 'react-router-hash-link';

export class About extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth
        };
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ windowWidth: window.innerWidth });
        });
    }

    render() {
        return (
            <div className="pt-4 pb-4">
                <div id="about" className="row p-4 mb-5" style={{ backgroundColor: '#f1e5c9', borderRadius: '6px' }}>
                    {this.state.windowWidth >= 1200 &&
                        <>
                            <div className="col-3">
                                <img src={imgSrc} className="w-100" style={{ borderRadius: '6px' }} />
                            </div>
                            <div className="col">
                                <h2 className="mb-4">About Me</h2>
                            <div className="mb-4">
                                Greetings! I’m Mary M.G. Nguyen, a Southeast Asian, first-generation scholar and a proud daughter of refugees. The mango, evocative of my heritage, symbolizes the love, hardship, and new beginnings my parents embraced. My academic journey at UCLA, enriched by my parent's sacrifices, helped me forge a dedication to nurturing socioeconomic equity for the Asian American, Native Hawaiian, and Pacific Islander (AANHPI) communities.
                            </div>
                            </div>
                        </>
                    }

                    {this.state.windowWidth < 1200 &&
                        <div className="col p-4 text-center" style={{ backgroundColor: '#f1e5c9', borderRadius: '6px' }} >
                            <img src={imgSrc} className="mb-4" style={{ borderRadius: '6px', width: '300px' }} />
                            <h2 className="mb-4">About Me</h2>
                            <div className="mb-4">
                                Greetings! I’m Mary M.G. Nguyen, a Southeast Asian, first-generation scholar and a proud daughter of refugees. The mango, evocative of my heritage, symbolizes the love, hardship, and new beginnings my parents embraced. My academic journey at UCLA, enriched by my parent's sacrifices, helped me forge a dedication to nurturing socioeconomic equity for the Asian American, Native Hawaiian, and Pacific Islander (AANHPI) communities.
                            </div>
                        </div>
                    }
                </div>
                <div id="gallery" className="mb-4">
                    <Gallery />
                </div>
                <div id="bltd" className="mb-5">
                    <Projects />
                </div>
                <div id="letsChat">
                    <Contact />
                </div>
                <HashLink to="#top" style={{
                    position: 'fixed', right: '15px', height: '50px', width: '50px', bottom: '15px', backgroundColor: '#eea74c', borderRadius: '50%', padding: '5px', boxShadow: '0px 0px 6px 0px grey', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-arrow-up" viewBox="0 0 16 16" width="25" height="25" fill="#444545">
                        <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"></path>
                    </svg>
                </HashLink>
            </div>
        );
    }
}
