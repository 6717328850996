import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import useWindowSize from '../../hooks/useWindowSize';
import seamangoLogo from '../../assets/images/SEAmangoLogo.png';

import './Header.scss';

export function Header(props) {
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const [collapsed, setCollapsed] = useState(true);
    function navigateToContact() {
        navigate('/contact');
    }

    function toggleNavbar() {
        setCollapsed(!collapsed);
    }

    return (
        <header id="header">
            {windowSize.width >= 768 &&
                <div className="container">
                    <div id="banner">
                        <img src={seamangoLogo} />
                    </div>
                    <div id="nav" className="d-flex align-items-center">
                        <div>
                            <Link to="#about" className="me-2">Home</Link>
                            <HashLink to="#gallery" className="me-2">Gallery</HashLink>
                            <HashLink to="#bltd">BTLD</HashLink>
                        </div>
                        <div className="pb-2 ms-auto">
                            <HashLink to="#letsChat" className="btn btn-primary">Let's Chat</HashLink>
                        </div>
                    </div>
                </div>
            }

            {windowSize.width < 768 &&
                <Navbar className="navbar-mobile navbar-expand-md navbar-toggleable-md ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to="/"><img className="website-logo-mobile" src={seamangoLogo} /></NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                    <Collapse className="d-md-inline-flex flex-md-row-reverse" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <HashLink tag={Link} className="text-light" to="#about">About</HashLink>
                            </NavItem>
                            <NavItem>
                                <HashLink tag={Link} className="text-light" to="#gallery">Gallery</HashLink>
                            </NavItem>
                            <NavItem>
                                <HashLink tag={Link} className="text-light" to="#bltd">BTLD</HashLink>
                            </NavItem>
                            <NavItem>
                                <HashLink tag={Link} className="text-light" to="#letsChat">Let's Chat</HashLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Navbar>
            }
        </header>
    )
}