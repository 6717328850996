import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './SmallImageSlider.scss';

const SmallImageSlider = (props) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(props.index ? props.index : 0);
    const maxSlide = props.datasource.length - 1;
    const ref = useRef();

    useEffect(() => {
        const slides = ref.current.querySelectorAll(".small-slide");

        slides.forEach((slide, i) => {
            slide.style.transform = `translateX(${100 * (i - currentSlideIndex)}%)`;
        });

        setTimeout(() => {
            nextSlide();
        }, 5000);
    });

    let nextSlide = () => {
        var slideIndex = currentSlideIndex;

        if (currentSlideIndex === maxSlide)
            setCurrentSlideIndex(0);
        else {
            slideIndex++;
            setCurrentSlideIndex(slideIndex);
        }
    }

    return (
        <div className="small-image-slider" ref={ref} >
            {props.datasource.map((item, i) => {
                return (
                    <div key={uuidv4()} className="small-slide" style={{ transform: `translateX(${i * 100}%)` }} >
                        <img src={item.src} />
                    </div>
                )
            })}
        </div>
    )
}

export default SmallImageSlider;