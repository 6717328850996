import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { v4 as uuidv4 } from 'uuid';
import ImageSlider from '../ImageSlider/ImageSlider';
import SmallImageSlider from '../SmallImageSlider/SmallImageSlider';
import useWindowSize from '../../hooks/useWindowSize';
import './PhotoGallery.scss';

const PhotoGallery = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);
    const [dataSource, setDataSource] = useState([]);
    const windowSize = useWindowSize();

    let renderGalleryItem = (item) => {
        if (Array.isArray(item.src))
            return (
                <div key={uuidv4()} className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 position-relative" style={{ cursor: 'pointer' }} onClick={() => { toggle(); setDataSource(item.src) }}>
                    <SmallImageSlider datasource={item.src} />
                    <div className="photo-label px-2 text-center">{item.title}</div>
                </div>
            )
        else
            return (
                <div key={uuidv4()} className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 position-relative" style={{ cursor: 'pointer' }} onClick={() => { toggle(); setDataSource([item]) }}>
                    <div className="photo-item" style={{ backgroundImage: `url(${item.src})`, backgroundSize: (item.backgroundSize ? item.backgroundSize : 'auto') }}></div>
                    <div className="photo-label px-2 text-center">{item.title}</div>
                </div>
            )
    }

    return (
        <div className="photo-gallery row">
            {props.datasource.map((item) => renderGalleryItem(item))}

            <Modal isOpen={isModalOpen} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    {windowSize.width > 768 &&
                        <ImageSlider datasource={dataSource} />
                    }

                    {windowSize.width < 767 &&
                        dataSource.map((item) => {
                            return (
                                <div key={uuidv4()} className="mb-4">
                                    <img src={item.src} className="w-100 mb-3" />
                                    <div className="pb-2">
                                        <h3>{item.title}</h3>
                                        {item.description}
                                    </div>
                                    <hr />
                                </div>
                            )
                        })
                    }
                </ModalBody>
            </Modal>
        </div>
    )
}

export default PhotoGallery;